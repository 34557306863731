import React from 'react';
import * as styles from './LeistungenTeaser.module.css';
import { graphql, Link, useStaticQuery } from "gatsby";

function LeistungenTeaser(props) {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                leistungComp {
                    body
                    head
                }
                leistungen {
                    head
                    id
                    kurz
                    lang
                    url
                }
            }
        }
    `)    
     
    const imgPath = "/images/"
    const _leistungen = data.configJson.leistungen;
    const leistungComp = data.configJson.leistungComp;
    const short = props.short;
    
    let leistungen = [];
    if (short) {
        leistungen = _leistungen.slice(0, 3);
    } else { 
        leistungen = _leistungen
    }


    
    return (
        <div className={styles.lmainContainer}>
            <div className={styles.lwrapper}>
                <div className={styles.ltextWrapper}>
                    {short
                        ? <h2 className={styles.lh2}>{leistungComp.head}</h2>
                        : null}
                    <p className={styles.lp}>{leistungComp.body}</p>
                </div>
                <div className={styles.lflexContainer}>
                { leistungen.map(leistung => {
                    return  <Link to={`/leistung_${leistung.id}`} 
                                className={styles.lleistungContainer}
                                key={leistung.head} 
                            >
                                    <img className={styles.lteaserImage} src={`${imgPath}${leistung.url}`} alt={leistung.head}/>
                                    <h3 className={styles.lh3}>{leistung.head}</h3>
                                    <p className={styles.lp}>{leistung.kurz}</p>
                            </Link>
                }) }
                </div>
                {short
                   ? <Link to="/leistungen" className="defaultBtn">Mehr erfahren</Link>
                   : <p onClick={() => window.history.back()} className="defaultBtn">zurück</p>
                }

            </div>
        </div>
    )
}

export default LeistungenTeaser



