import React from "react";
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout"
import Info from "../components/Info";
import LeistungenTeaser from "../components/LeistungenTeaser";
import './index.css';
import MiniHeader from "../components/MiniHeader";


const LeistungenPage = () => {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                sectionhead {
                    kontakt
                    leistungen
                    ueberuns
                }
            }
        }
    `)

  return (
    <>    
        <Helmet>
            <title>Leistungen</title>
            <meta name='description' content='Wir bieten eine Fülle von speziellen Behandlungen an. Die Behandlung findet üblicher Weise in unserer Praxis statt.'/>
            <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
            <meta property='og:title' content="Leistungen"/>
            <meta property='og:type' content='Website'/>
            <meta property='og:description' content='Wir bieten eine Fülle von speziellen Behandlungen an. Die Behandlung findet üblicher Weise in unserer Praxis statt.'/>
            <meta property='og:image' content=''/>
            <meta property='og:locale' content='de'/>
            <meta property='og:url' content='https://www.physiotherapie-winthagen.de/leistungen'/>
            <link rel="canonical" href="https://www.physiotherapie-winthagen.de/leistungen"/>
        </Helmet>  
        <Layout>
            <MiniHeader headline={data.configJson.sectionhead.leistungen}></MiniHeader>
            <LeistungenTeaser short={false}></LeistungenTeaser>
            <Info />
        </Layout>
    </>    
  )
}

export default LeistungenPage